import TransferOut from '../../../api/transfer/transferOut'
import fileDownload from 'js-file-download'

const transferOut = new TransferOut()

const transferOutModule = {
  namespaced: true,
  state: {
    item: {},
    load: false,
  },
  getters: {
    item(state) {
      return state.item
    },
    load(state) {
      return state.load
    },
  },
  mutations: {
    SET_ITEM: (state, item) => {
      state.item = item
    },
    SET_LOAD: (state, load) => {
      state.load = load
    },
  },
  actions: {
    hasRequest({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD', true);
        transferOut
          .hasRequest()
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    request({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD', true);
        transferOut
          .request()
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
}

export default transferOutModule
