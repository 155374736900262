export default [
    {
        path: '/transfer/internal/add',
        name: 'add_internal_transfer',
        component: () => import('@/views/for_student/academic_requests/internal_transfer/internal_transfer'),
        meta: { pageTitle: 'request internal transfer', breadcrumb: [{to: '/transfer/internal', text: 'Internal Transfer'},{ text: 'Add', active: true }] },
    },
    {
        path: '/transfer/internal',
        name: 'internal_transfer',
        component: () => import('@/views/for_student/academic_requests/internal_transfer/list'),
        meta: { pageTitle: 'request internal transfer', breadcrumb: [{ text: 'Internal Transfer', active: true }] },
    },

    {
        path: '/transfer/out',
        name: 'transfer_out',
        component: () => import('@/views/for_student/academic_requests/transfer_out'),
        meta: { pageTitle: 'request transfer out', breadcrumb: [{ text: 'Transfer Out', active: true }] },
    },
  ]
